import axios from '../_helpers/axios';

export const custom_event_alertService = {
    get,
    edit,
    get_data,
};

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('custom_event_alerts/' + data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('custom_event_alerts/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function get_data(data) {
  return new Promise((resolve, reject) => {
    axios.post('custom_event_alerts',data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
